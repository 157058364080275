import React, { FunctionComponent } from "react";
import RichText from "../RichText";
import Section from "../Section";
import "./styles.scss";

export interface TextEditorBlockInterface {
  _rawText: any;
  title?: string;
  _id?: string;
  titleAsH1?: boolean;
  isHalfSize?: boolean;
  bgColor?: {
    title: string;
  };
}

const TextEditorBlock: FunctionComponent<TextEditorBlockInterface> = props => {
  const { title, _rawText, bgColor, isHalfSize, titleAsH1 } = props;
  const theme = bgColor?.title ? bgColor?.title : "";
  return (
    <Section titleAsH1={titleAsH1} sectionClassName={`c-textEditor__wrapper ${props._id}`} title={title} theme={theme}>
      <div className={`c-textEditor__content ${isHalfSize ? "c-textEditor__halfSize" : ""}`}>
        <RichText data={_rawText} />
      </div>
    </Section>
  );
};

export default TextEditorBlock;
